<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
  >
    <el-select
      style="width: 100%"
      remote
      clearable
      filterable
      reserve-keyword
      placeholder="请使用Email搜索"
      :remote-method="remoteMethod"
      :loading="loading"
      :value="selectedUserName"
      @change="handleUserChange"
    >
      <el-option
        v-for="user in users"
        :key="user.id"
        :label="`${user.nickname}, ${user.email}`"
        :value="`${user.id}`"
      >
      </el-option>
    </el-select>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click.native="handleCancel">取消</el-button>
      <el-button
        type="primary"
        :disabled="!selectedUserId || users.length === 0"
        @click.native="handleConfirm"
      >提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { validEmail } from "@/utils/validate";
export default {
  name: "UserSelect",
  props: {
    title: {
      type: String,
      default: "",
    },
    selectedId: {
      type: String,
      default: "",
    },
    selectedName: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      dialogVisible: true,
      loading: false,
      users: [],
      selectedUserId: this.selectedId,
      selectedUserName: this.selectedName,
    };
  },
  mounted() {
    this.dialogVisible = true;
  },
  methods: {
    handleConfirm() {
      this.dialogVisible = false;
      this.$emit('onConfirm', { id: this.selectedUserId, name: this.selectedUserName })
    },
    handleCancel() {
      this.dialogVisible = false;
      this.$emit('onCancel')
    },
    handleUserChange(id) {
      const tmpUser = this.users.find((u) => u.id - id === 0);
      if (tmpUser) {
        this.selectedUserId = id;
        this.selectedUserName = tmpUser.nickname;
      }
    },
    remoteMethod(query) {
      if (this.loading) {
        return;
      }
      if (query !== '') {
        if (!validEmail(query)) {
          return
        }
        setTimeout(() => {
          this.loading = true;
          this.axios
            .get(`/api/user/search/email?email=${query}`)
            .then((data) => {
              this.users = data ? [data] : [];
            })
            .catch((rsp) => {
              // this.$notify.error({ title: "错误", message: "获取用户列表失败" });
            })
            .finally(() => {
              this.loading = false;
            });
        }, 200);
      } else {
        this.users = [];
      }
    },
  },
};
</script>
