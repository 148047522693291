<template>
  <section
    v-loading="loading"
    class="page-container"
  >
    <div class="page-section config-panel">
      <el-tabs value="cacheTab">
        <el-tab-pane
          label="缓存管理"
          name="cacheTab"
        >
          <el-form label-width="120px">
            <el-form-item label="缓存清理">
              <el-input
                v-model="cacheKey"
                type="text"
                placeholder="缓存 Key"
              />
            </el-form-item>
            <el-form-item>
              <div style="text-align: right">
                <el-button
                  :loading="loading"
                  type="primary"
                  @click="clearCache"
                > 清除缓存 </el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      cacheKey: ''
    }
  },
  methods: {
    async clearCache() {
      this.loading = true
      try {
        console.log(this.config)
        await this.axios.form('/api/admin/sys-admin/cache', {
          cacheKey: this.cacheKey,
        })
        this.$message({ message: '提交成功', type: 'success' })
      } catch (err) {
        this.$notify.error({ title: '错误', message: err.message })
      } finally {
        this.loading = false
      }
    },
    addNav() {
      if (!this.config.siteNavs) {
        this.config.siteNavs = []
      }
      this.config.siteNavs.push({
        title: '',
        url: '',
      })
    },
    delNav(index) {
      if (!this.config.siteNavs) {
        return
      }
      this.config.siteNavs.splice(index, 1)
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.config-panel {
  // margin: 20px;
  padding: 10px;
}

.config {
  padding: 10px 0;
}

.nav-panel {
  .navs {
    border: 1px solid #ddd;
    border-radius: 5px;

    .nav {
      padding: 5px 5px;
      margin: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      .nav-sort-btn {
        font-size: 21px;
        font-weight: 700;
        cursor: pointer;
        float: right;
      }
    }
  }

  .add-nav {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
