<template>
  <section class="page-container">
    <div
      ref="toolbar"
      class="toolbar"
    >
      <el-form
        :inline="true"
        :model="filters"
      >
        <el-form-item>
          <el-input
            v-model="filters.keyword"
            placeholder="关键字"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="list"
          >查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="handleAdd"
          >新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div
      ref="mainContent"
      :style="{ height: mainHeight }"
    >
      <el-table
        v-loading="listLoading"
        height="100%"
        :data="results"
        highlight-current-row
        stripe
        border
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column
          prop="id"
          label="编号"
          width="50"
        ></el-table-column>

        <el-table-column
          prop="nameEn"
          label="英文名称"
        ></el-table-column>

        <el-table-column
          prop="nameZh"
          label="中文名称"
        ></el-table-column>

        <el-table-column
          prop="code"
          label="代码"
          width="100"
        ></el-table-column>

        <el-table-column
          prop="level"
          label="层级"
          width="50"
        ></el-table-column>

        <el-table-column
          prop="keyword"
          label="搜索关键字"
        ></el-table-column>

        <el-table-column
          prop="isActive"
          label="启用"
          width="60"
        >
          <template slot-scope="scope">
            <span :class="{ active: scope.row.isActive }">
              {{ scope.row.isActive ? '✔' : '' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="Admin.nickname"
          label="区域管理员"
        >
          <template slot-scope="scope">
            <div class="align-justify">
              <span>{{ scope.row.Admin.nickname }}</span>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="showAdminSelector(scope.row)"
              >{{ scope.row.adminId ? '更改' : '设置' }}</el-button>
            </div>
          </template>

        </el-table-column>

        <el-table-column
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty />
        </template>
      </el-table>
    </div>

    <div
      ref="pagebar"
      class="pagebar"
    >
      <el-pagination
        :page-sizes="[20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="100px"
      >
        <el-form-item label="英文名称">
          <el-input v-model="addForm.nameEn"></el-input>
        </el-form-item>
        <el-form-item label="中文名称">
          <el-input v-model="addForm.nameZh"></el-input>
        </el-form-item>
        <el-form-item label="代码">
          <el-input v-model="addForm.code"></el-input>
        </el-form-item>
        <el-form-item label="层级">
          <el-input-number v-model="addForm.level"></el-input-number>
        </el-form-item>
        <el-form-item label="搜索关键字">
          <el-input v-model="addForm.keyword"></el-input>
        </el-form-item>
        <el-form-item label="启用状态">
          <el-select
            v-model="addForm.isActive"
            placeholder="请选择状态"
          >
            <el-option
              :value="true"
              label="启用"
            />
            <el-option
              :value="false"
              label="禁用"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="addLoading"
          @click.native="addSubmit"
        >提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-width="100px"
      >
        <el-input
          v-model="editForm.id"
          type="hidden"
        ></el-input>
        <el-form-item label="英文名称">
          <el-input v-model="editForm.nameEn"></el-input>
        </el-form-item>
        <el-form-item label="中文名称">
          <el-input v-model="editForm.nameZh"></el-input>
        </el-form-item>
        <el-form-item label="代码">
          <el-input v-model="editForm.code"></el-input>
        </el-form-item>
        <el-form-item label="层级">
          <el-input-number v-model="editForm.level"></el-input-number>
        </el-form-item>
        <el-form-item label="搜索关键字">
          <el-input v-model="editForm.keyword"></el-input>
        </el-form-item>
        <el-form-item label="启用状态">
          <el-select
            v-model="editForm.isActive"
            placeholder="请选择状态"
          >
            <el-option
              :value="true"
              label="启用"
            />
            <el-option
              :value="false"
              label="禁用"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="editLoading"
          @click.native="editSubmit"
        >提交</el-button>
      </div>
    </el-dialog>

    <user-select
      v-if="adminSelectVisible"
      title="选择区域管理员"
      :selected-id="'' + currentArea.adminId"
      :selected-name="currentArea.Admin.nickname"
      @onCancel="hideAdminSelector"
      @onConfirm="updateAreaAdmin"
    />
  </section>
</template>

<script>
import UserSelect from "@/components/UserSelect";
import mainHeight from "@/utils/mainHeight";

export default {
  components: {
    UserSelect
  },
  data() {
    return {
      mainHeight: "300px",
      results: [],
      listLoading: false,
      page: {},
      filters: {},
      selectedRows: [],

      addForm: {
        level: 1
      },
      addFormVisible: false,
      addLoading: false,

      editForm: {},
      editFormVisible: false,
      editLoading: false,

      currentArea: null,
      adminSelectVisible: false
    };
  },
  mounted() {
    mainHeight(this);
    this.list();
  },
  methods: {
    async list() {
      const params = Object.assign(this.filters, {
        page: this.page.page,
        limit: this.page.limit,
      });
      try {
        const data = await this.axios.form("/api/admin/area/list", params);
        this.results = data.results;
        this.page = data.page;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      } finally {
        this.listLoading = false;
      }
    },
    async handlePageChange(val) {
      this.page.page = val;
      await this.list();
    },
    async handleLimitChange(val) {
      this.page.limit = val;
      await this.list();
    },
    handleAdd() {
      this.addForm = {
        type: "word",
      };
      this.addFormVisible = true;
    },
    async addSubmit() {
      try {
        await this.axios.form("/api/admin/area/create", this.addForm);
        this.$message({ message: "提交成功", type: "success" });
        this.addFormVisible = false;
        await this.list();
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    showAdminSelector(row) {
      this.currentArea = row;
      this.adminSelectVisible = true;
    },
    hideAdminSelector() {
      this.currentArea = null;
      this.adminSelectVisible = false;
    },
    async updateAreaAdmin(admin) {
      this.adminSelectVisible = false;
      try {
        await this.axios.form("/api/admin/area/admin", {
          id: this.currentArea.id,
          adminId: admin.id,
        });
        this.results = this.results.map((area) => {
          if (area.id - this.currentArea.id === 0) {
            area.adminId = admin.id;
            area.Admin.nickname = admin.name;
          }
          return area;
        });
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async handleEdit(index, row) {
      try {
        const data = await this.axios.get("/api/admin/area/" + row.id);
        this.editForm = Object.assign({}, data);
        this.editFormVisible = true;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async editSubmit() {
      try {
        await this.axios.form("/api/admin/area/update", this.editForm);
        await this.list();
        this.editFormVisible = false;
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    async handleDelete(index, row) {
      try {
        await this.axios.form("/api/admin/area/delete", {
          id: row.id,
        });
        await this.list();
        this.$notify.success("删除成功");
      } catch (e) {
        this.$notify.error({ title: "错误", message: e.message || e });
      }
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.active {
  color: forestgreen
}
</style>
